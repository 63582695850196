import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import ConversationWppBg from "../../../../assets/imgs/banners/ConversationWpp.png";
import GreenLine from "../../../../assets/imgs/banners/greenLine.png";
import Leaf from "../../../../assets/imgs/banners/leaf.png";
import WppIcon from "../../../../assets/imgs/banners/wppIcon.png";
import WppScreen from "../../../../assets/imgs/banners/wppMock.png";
import { ReactComponent as WhatsaAppIcon } from "../../../../assets/svgs/WhatsAppIcon.svg";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const ConversationWpp = ({ handleHideBanner }) => {
  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const onClick = () => {
    handleHideBanner();
    logAnalyticsEvent("CLICK_BANNER_CONVERSATION_WPP");

    window.open("https://api.whatsapp.com/send?phone=5511977065185&text=Olá%20Bay", "_blank");
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_CONVERSATION_WPP");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div ref={ref} className={S.Container}>
        <div className={S.Wrapper}>
          <div className={S.WrapperContent}>
            <p className={S.Title}>
              <p>
                Pratique <span className={S.Bold}>conversação</span>
              </p>
              no WhatsApp
            </p>

            <div className={S.Description}>
              <div className={S.DescriptionItem}>
                <HiOutlineArrowNarrowRight className={S.DescriptionItemArrow} /> Escolha um tema
              </div>

              <div className={S.DescriptionItem}>
                <HiOutlineArrowNarrowRight className={S.DescriptionItemArrow} /> Envie áudios ou texto
              </div>

              <div className={S.DescriptionItem}>
                <HiOutlineArrowNarrowRight className={S.DescriptionItemArrow} /> Receba dicas e correções
              </div>
            </div>

            <button onClick={onClick} className={S.Button}>
              TESTAR GRÁTIS
              <WhatsaAppIcon width={22} height={22} />
            </button>
          </div>
        </div>

        <div className={S.ImageWrapper}>
          <img className={S.Image} src={ConversationWppBg} alt="bannerImage" />

          <div className={S.WrapperButtonClose}>
            <button className={S.ButtonClose} onClick={handleHideBanner}>
              <CloseIcon size={20} color="#6A51B2" />
            </button>
          </div>
        </div>

        <img
          style={{ width: 160, height: 160, position: "absolute", zIndex: 10, top: 0, left: 0, borderRadius: 20 }}
          src={Leaf}
          alt="bannerImage"
        />

        <img
          style={{ width: 165, height: 165, position: "absolute", zIndex: 10, marginRight: "80px" }}
          src={WppIcon}
          alt="bannerImage"
        />

        <img
          style={{ position: "absolute", zIndex: 2, bottom: "10px", left: 0, width: "50%" }}
          src={GreenLine}
          alt="bannerImage"
        />

        <img className={S.BackgroundImage} src={WppScreen} alt="bannerImage" />
      </div>
    </div>
  );
};

ConversationWpp.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default ConversationWpp;
