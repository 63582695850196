import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import Banner from "../../../../assets/imgs/banners/ConversationWppMobile.png";
import Line from "../../../../assets/imgs/banners/conversationWppMobileLine.png";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const ConversationWppMobile = ({ handleHideBanner }) => {
  const ref = useRef();

  useOnClickOutside(ref, () => handleHideBanner());

  const handleClick = () => {
    handleHideBanner();
    logAnalyticsEvent("CLICK_BANNER_CONVERSATION_WPP_MOBILE");

    window.open("https://api.whatsapp.com/send?phone=5511977065185&text=Olá%20Bay", "_blank");
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_CONVERSATION_WPP_MOBILE");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div className={S.Container}>
        <div ref={ref}>
          <div style={{ position: "relative", width: "100%" }}>
            <img className={S.Image} src={Banner} alt="bannerImage" />

            <div className={S.WrapperButtonClose}>
              <button className={S.ButtonClose} onClick={handleHideBanner}>
                <CloseIcon size={20} color="#6A51B2" />
              </button>
            </div>
          </div>

          <div className={S.Wrapper}>
            <p className={S.Title}>Pratique conversação no WhatsApp!</p>

            <img src={Line} style={{ marginTop: "11px", marginBottom: "8px" }} alt="quebra de linha" />

            <div className={S.Description}>
              <div className={S.DescriptionItem}>
                <HiOutlineArrowNarrowRight className={S.DescriptionItemArrow} /> Escolha um tema
              </div>

              <div className={S.DescriptionItem}>
                <HiOutlineArrowNarrowRight className={S.DescriptionItemArrow} /> Envie áudios ou texto
              </div>

              <div className={S.DescriptionItem}>
                <HiOutlineArrowNarrowRight className={S.DescriptionItemArrow} /> Receba dicas e correções
              </div>
            </div>

            <button onClick={handleClick} className={S.Button}>
              TESTAR GRÁTIS
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ConversationWppMobile.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default ConversationWppMobile;
