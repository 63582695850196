import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import Banner from "../../../../assets/imgs/banners/DiscountMobile.png";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const DiscountMobile = ({ handleHideBanner }) => {
  const ref = useRef();
  const history = useHistory();

  useOnClickOutside(ref, () => handleHideBanner());

  const handleClick = () => {
    handleHideBanner();
    logAnalyticsEvent("CLICK_BANNER_DISCOUNT_MOBILE");

    sessionStorage.setItem("coupon", "BANNER39");
    history.push("/planos/checkout");
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_DISCOUNT_MOBILE");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div className={S.Container}>
        <div ref={ref}>
          <div style={{ position: "relative", width: "100%" }}>
            <img className={S.Image} src={Banner} alt="bannerImage" />

            <div className={S.WrapperButtonClose}>
              <button className={S.ButtonClose} onClick={handleHideBanner}>
                <CloseIcon size={20} color="#6A51B2" />
              </button>
            </div>
          </div>

          <div className={S.Wrapper}>
            <p className={S.Title}>Aulas de conversação</p>

            <p className={S.Price}>
              R$ 39<span style={{ fontSize: "18px", letterSpacing: 0 }}>/mês</span>
            </p>

            <div className={S.Description}>
              <div className={S.DescriptionItem} style={{ width: "100%" }}>
                <span>+</span>Aulas com professores
              </div>

              <div style={{ display: "flex", gap: "7px", width: "100%" }}>
                <div className={S.DescriptionItem}>
                  <span>+</span>I.A
                </div>

                <div className={S.DescriptionItem}>
                  <span>+</span>Curso completo
                </div>
              </div>

              <div className={S.DescriptionItem} style={{ width: "100%" }}>
                <span>+</span>Cancelamento Gratuito
              </div>
            </div>

            <button onClick={handleClick} className={S.Button}>
              APROVEITE
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DiscountMobile.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default DiscountMobile;
