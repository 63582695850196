import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { AiOutlineClose as CloseIcon } from "react-icons/ai";
import { HiArrowRight } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import Background from "../../../../assets/imgs/banners/discount-background.png";
import PeopleBg from "../../../../assets/imgs/banners/discount-poeple.png";
import DiscountBadge from "../../../../assets/imgs/banners/discount.png";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import * as S from "./styles.module.css";

const Discount = ({ handleHideBanner }) => {
  const ref = useRef();
  const history = useHistory();

  useOnClickOutside(ref, () => handleHideBanner());

  const onClick = () => {
    handleHideBanner();
    logAnalyticsEvent("CLICK_BANNER_DISCOUNT");

    sessionStorage.setItem("coupon", "BANNER39");
    history.push("/planos/checkout");
  };

  useEffect(() => {
    logAnalyticsEvent("SHOW_BANNER_DISCOUNT");
  }, []);

  return (
    <div className={S.Backdrop}>
      <div ref={ref} className={S.Container}>
        <div className={S.Wrapper}>
          <div className={S.WrapperContent}>
            <p className={S.Title}>
              <p>Aulas de</p>
              conversação
            </p>

            <p className={S.Price}>
              R$ 39<span style={{ fontSize: "33px", letterSpacing: 0 }}>/mês</span>
            </p>

            <div className={S.Description}>
              <div className={S.DescriptionItem} style={{ width: "100%" }}>
                <span>+</span>Aulas com professores
              </div>

              <div style={{ display: "flex", gap: "7px", width: "100%" }}>
                <div className={S.DescriptionItem}>
                  <span>+</span>I.A
                </div>

                <div className={S.DescriptionItem}>
                  <span>+</span>Curso completo
                </div>
              </div>

              <div className={S.DescriptionItem} style={{ width: "100%" }}>
                <span>+</span>Cancelamento Gratuito
              </div>
            </div>

            <button onClick={onClick} className={S.Button}>
              APROVEITE
              <HiArrowRight style={{ marginLeft: "6px" }} fontSize={"24px"} />
            </button>
          </div>
        </div>

        <div className={S.ImageWrapper}>
          <img className={S.Image} src={Background} alt="bannerImage" />

          <div className={S.WrapperButtonClose} onClick={handleHideBanner}>
            <button className={S.ButtonClose}>
              <CloseIcon size={20} color="#6A51B2" />
            </button>
          </div>
        </div>

        <img
          style={{ position: "absolute", zIndex: 10, top: 0, left: 0, borderRadius: "16px" }}
          src={DiscountBadge}
          alt="bannerImage"
        />

        <img className={S.BackgroundImage} src={PeopleBg} alt="bannerImage" />
      </div>
    </div>
  );
};

Discount.propTypes = {
  handleHideBanner: PropTypes.func.isRequired,
};

export default Discount;
