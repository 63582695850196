import "./Dashboard.css";

import Conversation from "components/_Shared/NewBanners/Conversation";
import ConversationWpp from "components/_Shared/NewBanners/ConversationWpp";
import ConversationWppMobile from "components/_Shared/NewBanners/ConversationWppMobile";
import Discount from "components/_Shared/NewBanners/Discount";
import DiscountMobile from "components/_Shared/NewBanners/DiscountMobile";
import DownloadAppMobile from "components/_Shared/NewBanners/DownloadAppMobile";
import FreeClasses from "components/_Shared/NewBanners/FreeClasses";
import FreeClassesMobile from "components/_Shared/NewBanners/FreeClassesMobile";
import Recommendation from "components/_Shared/NewBanners/Recommendation";
import RecommendationMobile from "components/_Shared/NewBanners/RecommendationMobile";
import VendasMobile from "components/_Shared/NewBanners/VendaMobile";
import Vendas from "components/_Shared/NewBanners/Vendas";
import Youtube from "components/_Shared/NewBanners/Youtube";
import YoutubeMobile from "components/_Shared/NewBanners/YoutubeMobile";
import { UserContext } from "context/UserContext";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { useClickAway } from "react-use";
import { getRandomItemFromArray } from "utils/utils";

import ConversationMobile from "../_Shared/NewBanners/ConversationMobile";
import Premium from "../_Shared/NewBanners/Premium";
import PremiumMobile from "../_Shared/NewBanners/PremiumMobile";
import Slide from "../_Shared/Slide/Slide";
import ModalBannerCertificates from "../../components/_Shared/ModalBanner/Banners/ModalBannerCertificates/ModalBannerCertificates";
import ModalBannerConversation from "../../components/_Shared/ModalBanner/Banners/ModalBannerConversation/ModalBannerConversation";
import ModalBannerLiveClass from "../../components/_Shared/ModalBanner/Banners/ModalBannerLiveClasses/ModalBannerLiveClasses";
import ModalBannerPremium from "../../components/_Shared/ModalBanner/Banners/ModalBannerPremium/ModalBannerPremium";
import ModalBannerWhatsapp from "../../components/_Shared/ModalBanner/Banners/ModalBannerWhatsapp/ModalBannerWhatsapp";
import ModalPrivateClasses from "../../components/_Shared/ModalBanner/Banners/ModalPrivateClasses/ModalPrivateClasses";
import DownloadApp from "../../components/_Shared/NewBanners/DownloadApp";
import TestConversation from "../../components/_Shared/NewBanners/TestConversation";
import TestConversationMobile from "../../components/_Shared/NewBanners/TestConversationMobile";
import { useModalBanner } from "../../context/ModalBannerContext";
import { SlideContext } from "../../context/SlideContext";
import { fluxoBanners } from "./banners";
import Navbar from "./NavBar/NavBar";
import Sidebar from "./Sidebar/Sidebar";

const banners = {
  liveClasses: {
    name: "liveClasses",
    component: ModalBannerLiveClass,
  },
  whatsapp: {
    name: "whatsapp",
    component: ModalBannerWhatsapp,
  },
  certificates: {
    name: "certificates",
    component: ModalBannerCertificates,
  },
  privateClasses: {
    name: "privateClasses",
    component: ModalPrivateClasses,
  },
  premium: {
    name: "premium",
    component: ModalBannerPremium,
  },
  conversation: {
    name: "conversation",
    component: ModalBannerConversation,
  },

  downloadApp: {
    name: "downloadApp",
    component: DownloadApp,
  },
  downloadAppMobile: {
    name: "downloadAppMobile",
    component: DownloadAppMobile,
  },

  conversationNew: {
    name: "conversationNew",
    component: Conversation,
  },

  conversationNewMobile: {
    name: "conversationNewMobile",
    component: ConversationMobile,
  },

  premiumNew: {
    name: "premiumNew",
    component: Premium,
  },

  premiumNewMobile: {
    name: "premiumNewMobile",
    component: PremiumMobile,
  },

  freeClasses: {
    name: "freeClasses",
    component: FreeClasses,
  },

  freeClassesMobile: {
    name: "freeClassesMobile",
    component: FreeClassesMobile,
  },

  recommendation: {
    name: "recommendation",
    component: Recommendation,
  },

  recommendationMobile: {
    name: "recommendationMobile",
    component: RecommendationMobile,
  },

  youtube: {
    name: "youtube",
    component: Youtube,
  },

  youtubeMobile: {
    name: "youtubeMobile",
    component: YoutubeMobile,
  },

  vendas: {
    name: "vendas",
    component: Vendas,
  },

  vendasMobile: {
    name: "vendasMobile",
    component: VendasMobile,
  },

  testConversation: {
    name: "testConversation",
    component: TestConversation,
  },

  testConversationMobile: {
    name: "testConversationMobile",
    component: TestConversationMobile,
  },

  conversationWpp: {
    name: "conversationWpp",
    component: ConversationWpp,
  },

  conversationWppMobile: {
    name: "conversationWppMobile",
    component: ConversationWppMobile,
  },

  discount: {
    name: "discount",
    component: Discount,
  },

  discountMobile: {
    name: "discountMobile",
    component: DiscountMobile,
  },
};

const Dashboard = ({ children, userSidebarLinks }) => {
  const { user } = useContext(UserContext);

  const { showSlide, setShowSlide, slideComponent } = useContext(SlideContext);
  const { banner, showBanner, handleHideBanner, handleSetBanner } = useModalBanner();
  const [showSidebar, setShowSidebar] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  const [bannersIndex, setBannersIndex] = useState(0);

  let [showSlideDashboard, setShowSlideDashboard] = useState(false);

  const clickRef = React.useRef("");

  const BannerComponent = banner && banners[banner].component;

  useClickAway(clickRef, () => {
    if (window.innerWidth < 1024) {
      setShowSidebar(false);
    }
  });

  useEffect(() => {
    if (!showSlide) {
      const hideSlideTimeout = setTimeout(() => setShowSlideDashboard(false), 500);
      return () => clearTimeout(hideSlideTimeout);
    }

    if (showSlide) {
      const showSlideTimeout = setTimeout(() => setShowSlideDashboard(true), 100);
      return () => clearTimeout(showSlideTimeout);
    }
  }, [showSlide]);

  useEffect(() => {
    if (window.innerWidth < 1024) {
      setShowSlide(false);
      setShowSidebar(false);
    }
  }, [children]);

  useEffect(() => {
    if (!showSidebar) {
      setToggleMenu(false);
    }
  }, [showSidebar]);

  useEffect(() => {
    const alreadyShowed = sessionStorage.getItem("alreadyShowed");
    const index = localStorage.getItem("BannerIndex");

    if (index) {
      setBannersIndex(parseInt(index));
    }

    if (alreadyShowed) {
      return;
    }

    const onBoardingData = JSON.parse(localStorage.getItem("userData"));
    const wantTestClass = onBoardingData?.wantTestClass === "Sim";

    // Logica pra sobrescrever e mostrar os 2 banners de wpp e desconto
    const discountBanner = window.innerWidth < 1024 ? "discountMobile" : "discount";
    const wppBanner = window.innerWidth < 1024 ? "conversationWppMobile" : "conversationWpp";

    if (user?.role === "LEAD" && !wantTestClass) {
      handleSetBanner(getRandomItemFromArray([discountBanner, wppBanner]));
      sessionStorage.setItem("alreadyShowed", true);
      return;
    }
    // Fim logica

    // Trilha
    if (user === null || user?.student?.status === "ACTIVE" || user?.role === "TEACHER" || !onBoardingData) {
      return;
    }

    let fluxo = null;

    const isUnder18 = onBoardingData?.age === "Menor de 18";

    if (isUnder18) {
      fluxo = fluxoBanners.under18;
    }

    if (!isUnder18 && wantTestClass) {
      fluxo = fluxoBanners.wantLiveClasses;
    }

    if (!isUnder18 && !wantTestClass) {
      fluxo = fluxoBanners.dontWantLiveClasses;
    }

    const size = window.innerWidth < 1024 ? "mobile" : "desktop";

    if (bannersIndex >= fluxo[size].length) {
      if (!fluxo.repeat) {
        const lastBanner = fluxo[size][fluxo[size].length - 1];
        handleSetBanner(lastBanner);
        sessionStorage.setItem("alreadyShowed", true);
        return;
      }

      setBannersIndex(0);
      localStorage.setItem("BannerIndex", 0);
      const actualBanner = fluxo[size][0];

      handleSetBanner(actualBanner);
      sessionStorage.setItem("alreadyShowed", true);

      return;
    }

    const actualBanner = fluxo[size][bannersIndex];

    localStorage.setItem("BannerIndex", bannersIndex + 1);
    sessionStorage.setItem("alreadyShowed", true);

    handleSetBanner(actualBanner);
  }, [user?.role]);

  return (
    <>
      {showBanner && banner && <BannerComponent handleHideBanner={handleHideBanner} variant={1} />}

      {showSlideDashboard && <Slide isCloseSlide={showSlide}>{slideComponent}</Slide>}

      <Sidebar
        isMenuVisible={showSidebar}
        setMenuVisible={setShowSidebar}
        toggleMenu={toggleMenu}
        setToggleMenu={setToggleMenu}
        userSidebarLinks={userSidebarLinks}
        reference={clickRef}
      />

      <Navbar isMenuVisible={showSidebar} setMenuVisible={setShowSidebar} toggleMenu={toggleMenu} />

      <div className={`index-container ${toggleMenu && "expand-container"}`}>{children}</div>

      {/* <AdBar /> */}
    </>
  );
};

Dashboard.propTypes = {
  children: PropTypes.node.isRequired,
  userSidebarLinks: PropTypes.array,
};

export default Dashboard;
