import PaymentWarningStudent from "components/_Shared/PaymentWarningStudent/PaymentWarningStudent";
import React, { useContext, useEffect, useState } from "react";

import { PurpleButton } from "../../../_Shared/Form/index";
import InfoPlan from "../../../_Shared/Slide/InfoPlan/InfoPlan";
import SelectPlan from "../../../_Shared/Slide/SelectPlan/SelectPlan";
import {
  SpinnerWhite,
  SpinnerPurple,
  Jumbotron,
  PlaneActive,
  CalendarGreen,
  Money,
  Ring,
  Boleto,
  Copy,
} from "../../../../assets/svgs/index";
import { useChatbot } from "../../../../context/ChatbotContext";
import { NavbarContext } from "../../../../context/NavbarContext";
import { SlideContext } from "../../../../context/SlideContext";
import { UserContext } from "../../../../context/UserContext";
import useRequest from "../../../../hooks/useRequest";
import { getCompanySvg } from "../../../../utils/creditCardCompany";
import { getMonthYear, getDayMonthYear, getDayMonth, getDayMonthName } from "../../../../utils/dates";
import { getUser } from "../../../../utils/localStorage";
import { getStatusPlanName, getColorByStatus } from "../../../../utils/status";
import HistoryLevy from "./HistoryLevy";

import "./MyPlans.css";

const MyPlans = () => {
  const { setShowSlide, setSlideComponent } = useContext(SlideContext);
  const { credits, user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [billHistory, setBillHistory] = useState(null);
  const [activeBill, setActiveBill] = useState(null);
  const [isLead, setIsLead] = useState(true);
  const [isAppSubscription, setIsAppSubscription] = useState(false);
  const { request } = useRequest();
  const { setNavigationLinks } = useContext(NavbarContext);
  const { setShowTutor, showTutor } = useChatbot();
  const [pixCode, setPixCode] = useState("");

  const isMobile = window.innerWidth < 1024;

  const openPlans = () => {
    setShowSlide(true);
    setSlideComponent(<SelectPlan changePlan={false} />);
  };

  // Abre o chat do tawkto
  const openChat = () => {
    const config = window.Tawk_API;
    config.toggle();
  };

  const getPlans = async () => {
    const user = getUser();

    if (!user) {
      return;
    }

    const isVindi = user?.student?.activeSubscription?.kind === "VINDI";
    setIsAppSubscription(!isVindi);

    if (user.role === "STUDENT" && isVindi) {
      const { bills } = await request("GET", "/subscription/bill/history");
      const activeBill = await request("GET", "/subscription/activeSubscription");

      setIsLead(false);
      activeBill.status = activeBill?.bill?.status || activeBill.status;
      setActiveBill(activeBill);
      setBillHistory(bills);
      setPixCode(
        activeBill.bill?.charges[activeBill?.bill?.charges?.length - 1]?.last_transaction?.gateway_response_fields
          ?.qrcode_original_path,
      );
    }
    setLoading(false);
  };

  const copyPixCode = () => {
    if (pixCode === "Código copiado com sucesso!") {
      return;
    }

    navigator.clipboard.writeText(pixCode);
    setTimeout(() => setPixCode(pixCode), 1100);
    setPixCode("Código copiado com sucesso!");
  };

  useEffect(() => {
    setIsLead(true);
    getPlans();

    const arrLinks = [
      { title: "Início", path: "/" },
      { title: "Planos", path: "/assinatura" },
    ];

    setNavigationLinks(arrLinks);

    const originalTutorState = showTutor;
    setShowTutor(false);

    return () => setShowTutor(originalTutorState);
  }, []);

  const handleClick = (component) => {
    setShowSlide(true);
    setSlideComponent(component);
  };

  return (
    <>
      {user?.student?.status === "CANCELED" && (
        <PaymentWarningStudent
          color="red"
          buttonMessage="Renovar agora"
          message="Você cancelou o seu plano. Renove a qualquer momento para voltar com seus estudos!"
          link="/planos"
        />
      )}
      <div className="minhas-aulas-jumbotron shadow-md">
        <div className="grid grid-cols-1 2xl:grid-cols-4 lg:grid-cols-2">
          <div className="2xl:col-span-2">
            <div className="px-4 ml-2">
              <div className="text-white font-medium py-3">
                <p className="text-xs">Início / Assinatura</p>
                <h1 className="text-3xl mt-5">Minha assinatura</h1>
              </div>
              {loading ? (
                <SpinnerWhite className="w-12 mt-10 m-auto animate-spin" />
              ) : (
                <>
                  {!isLead && (
                    <>
                      {/**
                       * PurgeCSS:
                       * text-red-500
                       * text-green-500
                       * text-yellow-500
                       * text-gray-500
                       * text-purple-500
                       * text-indigo-500
                       * text-blue-500
                       * text-pink-500
                       *
                       */}

                      {/* <div className="minhas-aulas-jumbotron-card mt-3 grid grid-cols-3 sm:grid-cols-5 items-center rounded-lg"> */}

                      <div className="minhas-aulas-jumbotron-card rounded-lg">
                        <div className="col-span-4 flex items-center gap-4">
                          <div className="vertical-line" />
                          <div className="bg-white rounded-3xl p-2">
                            <PlaneActive />
                          </div>
                          <div>
                            <p className="text-white font-bold">{activeBill && activeBill.plan.name}</p>
                            {activeBill.plan.lessonType !== "CONVERSATION" ? (
                              <p className="text-yellow-700 text-xs sm:text-sm">
                                Plano de{" "}
                                <span className="font-bold">
                                  {activeBill.plan.lessonsCount} {activeBill.plan.lessonsCount > 1 ? "aulas" : "aula"}
                                </span>{" "}
                                de{" "}
                                <span className="font-bold">
                                  {activeBill.plan.lessonType === "FIFTY" ? "50" : "25"} min{" "}
                                </span>
                                por mês
                              </p>
                            ) : (
                              <p>Plano de aulas ao vivo</p>
                            )}
                          </div>
                        </div>
                        <div className="col-span-1 col-start-6 ml-5">
                          <button
                            onClick={() => handleClick(<InfoPlan activeBill={activeBill} getPlans={getPlans} />)}
                            className="text-xs sm:text-sm rounded-lg font-bold myplans-card-btn p-4 px-4 mr-5">
                            Detalhes do plano
                          </button>
                        </div>
                      </div>
                      <div className="flex gap-16 mt-5">
                        <div>
                          <p className="text-yellow-800 font-medium text-xs sm:text-sm">Próxima Cobrança</p>
                          <p className="text-yellow-800 capitalize">
                            {activeBill && activeBill?.status !== "canceled" && user?.student?.status !== "CANCELED"
                              ? getDayMonthYear(activeBill?.nextBillingAt)
                              : "Cancelado"}
                          </p>
                        </div>
                        {activeBill.plan.lessonType !== "CONVERSATION" && (
                          <div>
                            <p className="text-yellow-800 font-medium text-sm">Aulas disponíveis</p>
                            <p className="text-yellow-800">Restam {credits?.available} aulas</p>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {isLead && isAppSubscription && (
                    <p className="text-lg">
                      Para ver sua assinatura, entre pelo seu aplicativo ou{" "}
                      <a
                        style={{ color: "#FFFF", textDecoration: "underline", fontWeight: "600" }}
                        href={
                          activeBill?.kind === "PLAY_STORE"
                            ? "https://play.google.com/store/account/subscriptions"
                            : "https://apps.apple.com/account/subscriptions"
                        }>
                        por esse link
                      </a>
                    </p>
                  )}

                  {isLead && !isAppSubscription && (
                    <p className="text-lg">
                      Você ainda não assinou um plano.{" "}
                      <button
                        onClick={openPlans}
                        style={{ color: "#FFFF", textDecoration: "underline", fontWeight: "600" }}>
                        Clique aqui
                      </button>{" "}
                      para ver nossos planos!
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="mt-4 sm:mt-0">
            <Jumbotron className="mt-32 sm:mt-0 max-h-[18em]" />
          </div>
        </div>
        {loading ? (
          <SpinnerPurple className="w-12 m-auto mt-24 animate-spin" />
        ) : (
          <div className="grid grid-cols-1 2xl:grid-cols-4 lg:grid-cols-2 mt-6">
            <div className="col-span-3">
              {!isLead && (
                <div className="px-4 mt-4">
                  {activeBill.bill.charges?.length > 0 && (
                    <>
                      <h2 className="text-xl font-medium mt-9 mb-2">Assinatura Atual</h2>
                      {activeBill.bill.charges[activeBill.bill.charges.length - 1].payment_method.code ===
                        "credit_card" && (
                        <div className="grid grid-cols-1 lg:grid-cols-3">
                          <div className="col-span-2 rounded-xl shadow-lg border bg-white py-10 px-4">
                            <p className="capitalize">{getMonthYear(activeBill.bill.created_at)}</p>
                            <div className="grid grid-cols-1 lg:grid-cols-2 mt-2">
                              <div className="flex gap-10 mb-6 sm:mb-0 justify-between lg:justify-start">
                                <div>
                                  <div className="flex">
                                    <Money />
                                    <p className="font-gray text-sm">Valor</p>
                                  </div>
                                  <p className="ml-6">R$ {activeBill.bill.amount}</p>
                                </div>
                                <div>
                                  <div className="flex items-center gap-1">
                                    <CalendarGreen />
                                    <p className="font-gray text-sm">Vencimento</p>
                                  </div>
                                  <p className="ml-6">{getDayMonthName(activeBill.nextBillingAt)}</p>
                                </div>
                              </div>
                              <div className="flex justify-between">
                                <div>
                                  <p className="text-sm font-gray">Cartão de crédito</p>
                                  <div className="flex justify-between items-center">
                                    {getCompanySvg(
                                      activeBill.bill.charges[activeBill.bill.charges.length - 1]?.last_transaction
                                        .payment_profile?.payment_company?.id,
                                    )}
                                    <p className="text-md font-gray">
                                      ••••{" "}
                                      {activeBill.bill.charges[activeBill.bill.charges.length - 1]?.last_transaction
                                        ?.payment_profile?.card_number_last_four ?? "####"}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex items-center gap-1">
                                  <span className={`text-${getColorByStatus(activeBill.status)}-500`}>
                                    <Ring />
                                  </span>
                                  <p>{getStatusPlanName(activeBill.status)} </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="needHelp rounded-xl shadow-lg border p-5 mt-5"
                            style={{
                              background: "#30125C",
                              boxShadow:
                                "0rem 0.125rem 0.25rem -0.125rem rgba(24, 39, 75, 0.12), 0px 0.25rem 0.25rem -0.125rem rgba(24, 39, 75, 0.08)",
                            }}>
                            <h1 className="text-2xl" style={{ color: "#C3A2F5" }}>
                              Precisa de ajuda?
                            </h1>
                            <p className="mt-2 text-sm tracking-widest" style={{ color: "#FBF7FF" }}>
                              Quer alterar seu plano, mudar a forma de pagamento ou nota fiscal? Se tiver qualquer
                              dúvida, pode nos chamar por aqui!
                            </p>

                            <PurpleButton onClick={openChat} className="mt-3 btnPayment">
                              Abrir chat
                            </PurpleButton>
                          </div>
                        </div>
                      )}
                      {["bank_slip", "pix_bank_slip"].includes(
                        activeBill.bill.charges[activeBill.bill.charges.length - 1].payment_method.code,
                      ) && (
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
                          <div className="col-span-2 rounded-xl shadow-xl border">
                            <div className="grid grid-cols-1 lg:grid-cols-8 p-4">
                              <div className="col-span-5 p-4">
                                <p className="capitalize">{getMonthYear(activeBill.bill.created_at)}</p>
                                <div className="flex gap-10">
                                  <div>
                                    <div className="flex items-center gap-1">
                                      <Money />
                                      <p>Valor</p>
                                    </div>
                                    <p>R${activeBill && activeBill.bill.amount}</p>
                                  </div>
                                  <div>
                                    <div className="flex items-center gap-1">
                                      <CalendarGreen />
                                      <p>Vencimento</p>
                                    </div>
                                    <p className="capitalize">{getDayMonth(activeBill.bill.due_at)}</p>
                                  </div>
                                </div>
                                <div className="mt-4">
                                  <div className="flex items-center gap-1">
                                    <Boleto />
                                    <p>Código Pix</p>
                                  </div>
                                  <p className="text-md break-words flex">
                                    <div style={{ width: "250px" }}>{pixCode?.substring(0, 29) + "..."}</div>

                                    <div style={{ height: "40px", width: "40px" }}>
                                      <Copy cursor="pointer" className="ml-1" onClick={copyPixCode} />
                                    </div>
                                  </p>

                                  {/* <div className="flex items-center gap-1 mt-2">
                                    <Boleto />
                                    <p>Pagar por boleto</p>
                                  </div>
                                  <button
                                    className="w-2/4 text-sm rounded bg-purple-200 text-purple-700 p-2 mt-2"
                                    onClick={() =>
                                      window.open(activeBill.bill.charges[activeBill.bill.charges.length - 1].print_url)
                                    }>
                                    Baixar boleto
                                  </button> */}
                                </div>
                              </div>
                              <div
                                className={`col-span-3 lg:col-start-6 flex flex-col items-${
                                  isMobile ? "center" : "end"
                                }`}>
                                <div className="flex justify-end items-center gap-1 mt-1 mr-3">
                                  <span className={`text-${getColorByStatus(activeBill.status)}-500`}>
                                    <Ring />
                                  </span>
                                  <p>{getStatusPlanName(activeBill.status)}</p>
                                </div>
                                {activeBill.bill.status !== "paid" && activeBill.bill.status !== "canceled" && (
                                  <img
                                    src={
                                      activeBill.bill?.charges[activeBill?.bill?.charges?.length - 1]?.last_transaction
                                        ?.gateway_response_fields?.qrcode_path
                                    }
                                    alt="QRCode pix"
                                    className="mr-3"
                                    style={{ width: "138px" }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            className="rounded-xl shadow-lg border p-5"
                            style={{
                              background: "#30125C",
                              boxShadow:
                                "0rem 0.125rem 0.25rem -0.125rem rgba(24, 39, 75, 0.12), 0px 0.25rem 0.25rem -0.125rem rgba(24, 39, 75, 0.08)",
                            }}>
                            <h1 className="text-2xl" style={{ color: "#C3A2F5" }}>
                              Precisa de ajuda?
                            </h1>
                            <p className="mt-2 text-sm tracking-widest" style={{ color: "#FBF7FF" }}>
                              Quer alterar seu plano, mudar a forma de pagamento ou nota fiscal? Se tiver qualquer
                              dúvida, pode nos chamar por aqui!
                            </p>

                            <PurpleButton onClick={openChat} className="mt-3 btnPayment">
                              Abrir chat
                            </PurpleButton>
                          </div>
                        </div>
                      )}

                      {activeBill.bill.charges[activeBill.bill.charges.length - 1].payment_method.code === "cash" && (
                        <div className="grid grid-cols-1 lg:grid-cols-3" style={{ columnGap: "13px" }}>
                          <div className="col-span-2 rounded-xl shadow-lg border bg-white py-10 px-4">
                            <p className="capitalize">{getMonthYear(activeBill.bill.created_at)}</p>
                            <div className="grid grid-cols-1 lg:grid-cols-2 mt-2">
                              <div className="flex gap-10 justify-between lg:justify-start">
                                <div>
                                  <div className="flex">
                                    <Money />
                                    <p className="font-gray text-sm">Valor</p>
                                  </div>
                                  <p className="text-center">R$ {activeBill.bill.amount}</p>
                                </div>
                                <div>
                                  <div className="flex items-center gap-1">
                                    <CalendarGreen />
                                    <p className="font-gray text-sm">Vencimento</p>
                                  </div>
                                  <p className="text-center">{getDayMonthName(activeBill.nextBillingAt)}</p>
                                </div>
                              </div>
                              <div className="flex justify-between">
                                <div>
                                  <div className="flex items-center gap-1">
                                    <Money />
                                    <p className="font-gray text-sm">Método</p>
                                  </div>
                                  <div className="flex gap-2">
                                    <p className="text-center">Baixa manual</p>
                                  </div>
                                </div>
                                <div className="flex items-center gap-1">
                                  <span className={`text-${getColorByStatus(activeBill.status)}-500`}>
                                    <Ring />
                                  </span>
                                  <p>{getStatusPlanName(activeBill.status)} </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="rounded-xl shadow-lg border p-5"
                            style={{
                              background: "#30125C",
                              boxShadow:
                                "0rem 0.125rem 0.25rem -0.125rem rgba(24, 39, 75, 0.12), 0px 0.25rem 0.25rem -0.125rem rgba(24, 39, 75, 0.08)",
                            }}>
                            <h1 className="text-2xl" style={{ color: "#C3A2F5" }}>
                              Precisa de ajuda?
                            </h1>
                            <p className="mt-2 text-sm tracking-widest" style={{ color: "#FBF7FF" }}>
                              Quer alterar seu plano, mudar a forma de pagamento ou nota fiscal? Se tiver qualquer
                              dúvida, pode nos chamar por aqui!
                            </p>

                            <PurpleButton onClick={openChat} className="mt-3 btnPayment">
                              Abrir chat
                            </PurpleButton>
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <h3 className="text-xl font-medium mt-7 mb-2">Histórico de cobranças</h3>
                  <div className="grid grid-cols-1 sm:grid-cols-3 gap-3 mt-3 mb-10">
                    {billHistory &&
                      billHistory
                        .filter((billItem) => billItem.charges.length > 0)
                        .map((bill) => (
                          <HistoryLevy
                            key={bill.id}
                            price={bill.amount}
                            utc={bill.created_at}
                            status={bill.charges[bill.charges.length - 1].status}
                            charge={bill.charges[bill.charges.length - 1]}
                            billId={bill.id}
                          />
                        ))}
                    {/* todo: remover os aprovados mocados */}
                    {/* <HistoryLevy
                    key={1111}
                    price={"20"}
                    utc={"Wed Jan 20 2021 19:55:22 GMT-0300"}
                    status={"Pagamento Realizado"}
                    charge={{ payment_method: { code: "bank_slip" } }}
                  />
                  <HistoryLevy
                    key={2222}
                    price={"3000"}
                    utc={"Wed Jan 20 2021 19:55:22 GMT-0300"}
                    status={"Pagamento Realizado"}
                    charge={{
                      payment_method: { code: "credit_card" },
                      last_transaction: {
                        payment_profile: { card_number_last_four: "1773", payment_company: { id: 13 } },
                      },
                    }}
                  /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MyPlans;
