import { Telephone } from "assets/svgs";
import { useUserContext } from "context/UserContext";
import useRequest from "hooks/useRequest";
import PropTypes from "prop-types";
import React, { useState, useRef } from "react";
import { IoMdClose } from "react-icons/io";
import { IoSendSharp } from "react-icons/io5";
import { logAnalyticsEvent } from "utils/firebase-analytics";

import BotResponse from "../BotResponse/BotResponse";
import CallToAction from "../CallToAction/CallToAction";
import Caption from "../Caption/Caption";
import QuickReplies from "../QuickReplies/QuickReplies";
import UserResponse from "../UserResponse/UserResponse";
import S from "./styles.module.css";

const components = {
  Caption,
  QuickReplies,
  BotResponse,
  UserResponse,
  CallToAction,
};

const Chat = ({ initialMessages, showChat, setShowChat, setShowModal, isGif = true, showHeaderAndFooter = true }) => {
  const conversationRef = useRef(null);
  const iframeRef = useRef(null);

  const { request } = useRequest();
  const { isUserExpired } = useUserContext();

  const [messages, setMessages] = useState(initialMessages);
  const [disabled, setDisabled] = useState(true);
  const [inputedMessage, setInputedMessage] = useState("");

  const getTutorChat = async (message) => {
    try {
      const body = {
        message,
      };

      const threadIdStorage = sessionStorage.getItem("threadIdInternal");

      if (threadIdStorage) {
        body.threadId = threadIdStorage;
      }

      const { output, threadId } = await request("POST", "/tutor/chat", body);

      sessionStorage.setItem("threadIdInternal", threadId || "");

      return output;
    } catch (error) {
      console.error("Error fetching tutor chat:", error);
    }
  };

  const generateResponse = async (userMessage) => {
    if (userMessage === "Liberar cupom de desconto") {
      logAnalyticsEvent("BAY_opt_cupom");
      setDisabled(true);

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          type: "Caption",
          props: {
            isUser: true,
          },
        },
        {
          type: "UserResponse",
          props: {
            message: userMessage,
          },
        },
        {
          type: "Caption",
        },
        {
          type: "BotResponse",
          props: {
            message: "Aqui está seu cupom de desconto!",
            isLoading: true,
            loadingDuration: 400,
          },
        },
      ]);

      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            type: "CallToAction",
            props: {
              type: "coupon",
            },
          },
        ]);
      }, 540);

      return;
    }

    if (userMessage === "Quero praticar conversação com você") {
      logAnalyticsEvent("BAY_opt_praticar");
      setDisabled(true);
      setShowModal(true);

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          type: "Caption",
          props: {
            isUser: true,
          },
        },
        {
          type: "UserResponse",
          props: {
            message: userMessage,
          },
        },
        {
          type: "Caption",
        },
        {
          type: "BotResponse",
          props: {
            message: "Para praticar conversação comigo, baixe o aplicativo da EnglishBay!",
            isLoading: true,
            loadingDuration: 1000,
          },
        },
      ]);

      return;
    }

    if (userMessage === "Quero falar com o suporte") {
      logAnalyticsEvent("BAY_opt_suporte");
      setDisabled(true);

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          type: "Caption",
          props: {
            isUser: true,
          },
        },
        {
          type: "UserResponse",
          props: {
            message: userMessage,
          },
        },
        {
          type: "Caption",
        },
        {
          type: "CallToAction",
          props: {
            type: "whatsapp",
          },
        },
      ]);

      return;
    }

    if (userMessage === "Quero tirar uma dúvida sobre inglês") {
      logAnalyticsEvent("BAY_opt_duvida");
    }

    if (isUserExpired) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          type: "Caption",
          props: {
            isUser: true,
          },
        },
        {
          type: "UserResponse",
          props: {
            message: userMessage,
          },
        },
        {
          type: "Caption",
        },
        {
          type: "CallToAction",
          props: {
            type: userMessage === "Quero tirar uma dúvida sobre inglês" ? "duvida" : "revisao",
          },
        },
      ]);

      return;
    }

    if (userMessage === "Quero uma revisão com IA") {
      logAnalyticsEvent("BAY_opt_revisao");
      setDisabled(true);
    }

    if (userMessage === "Quero tirar uma dúvida sobre inglês" || userMessage === "Quero uma revisão com IA") {
      sessionStorage.setItem("threadIdInternal", "");
    }

    const messagesObject = [
      {
        type: "Caption",
        props: {
          isUser: true,
        },
      },
      {
        type: "UserResponse",
        props: {
          message: userMessage,
        },
      },
      {
        type: "Caption",
      },
      {
        type: "BotResponse",
        props: {
          message: "Loading...",
          isLoading: true,
        },
      },
    ];

    setMessages((prevMessages) => [...prevMessages, ...messagesObject]);

    const response = await getTutorChat(userMessage);

    if (!response) {
      setMessages((prevMessages) => {
        return [
          ...prevMessages.filter((msg) => !msg.props?.isLoading),
          {
            type: "QuickReplies",
            props: {
              message: "Desculpe, não entendi o que você quis dizer. Poderia tentar novamente?",
            },
          },
        ];
      });

      setTimeout(() => {
        handleScrollToBottom();
        setDisabled(true);
      }, 500);

      return;
    }

    const normalizedResponse = Array.isArray(response) ? response : [response];

    const messagesInternal = normalizedResponse.map((msg, index) => ({
      type: "BotResponse",
      props: {
        message: msg,
        isLoading: true,
        loadingDuration: 1000 + index * 100,
      },
    }));

    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.map((msg, index) =>
        index === prevMessages.length - 1 && msg.type === "BotResponse" && msg.props.isLoading
          ? {
              ...msg,
              props: {
                ...msg.props,
                isLoading: false,
                message: response[0],
              },
            }
          : msg,
      );

      return [...updatedMessages, ...messagesInternal.slice(1)];
    });

    if (userMessage === "Quero tirar uma dúvida sobre inglês") {
      setDisabled(false);
    }

    setTimeout(() => {
      handleScrollToBottom();
    }, 100);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!inputedMessage) {
      return;
    }

    logAnalyticsEvent("BAY_message");
    setInputedMessage("");
    generateResponse(inputedMessage);
  };

  const handleClose = () => {
    setShowChat(false);
    setMessages(initialMessages);
    setDisabled(true);
  };

  const triggerWebView = (text) => {
    if (iframeRef.current) {
      const payload = { text, token: "123" };
      iframeRef.current.contentWindow.postMessage(payload, "*");
    }
  };

  const handleScrollToBottom = () => {
    conversationRef.current.scrollTo({
      top: conversationRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  return (
    <div className={S.Wrapper} style={{ display: showChat ? "flex" : "none" }}>
      {showHeaderAndFooter && (
        <div className={S.Header}>
          <div className={S.HeaderInfo}>
            <div className={S.Avatar}>
              <img src="https://media.englishbay.com.br/Frame%2040169.png" alt="Chatbot" />
              <div className={S.Status} />
            </div>
            <div>
              <h3>Bay</h3>
              <span>Sua professora IA</span>
            </div>
          </div>
          <IoMdClose className="cursor-pointer" size={22} onClick={handleClose} />
        </div>
      )}

      <div>
        {isGif ? (
          <img alt="gif" src="https://media.englishbay.com.br/bay-neutral.gif" className={S.Iframe} />
        ) : (
          <iframe
            ref={iframeRef}
            className={S.Iframe}
            src="https://avatar.englishbay.com.br/"
            title="Chatbot"
            allow="autoplay"
            allowFullScreen
            allowtransparency
          />
        )}
      </div>

      <div className={S.Conversation} ref={conversationRef}>
        {messages &&
          messages.map((message, index) => {
            const Component = components[message.type];
            return (
              <Component
                key={index}
                {...message.props}
                handleRepliesClick={generateResponse}
                handleScrollToBottom={handleScrollToBottom}
                setShowChat={setShowChat}
                triggerWebView={triggerWebView}
              />
            );
          })}
      </div>

      <div className={S.Footer}>
        <form onSubmit={handleSubmit}>
          <button
            onClick={() => {
              logAnalyticsEvent("BAY_click_telefone");
              setShowModal(true);
            }}
            className={S.CallButton}
            type="button">
            <Telephone color="#733eb1" />
          </button>

          <input
            value={inputedMessage}
            onChange={({ target }) => setInputedMessage(target.value)}
            type="text"
            placeholder="Digite sua mensagem aqui"
            disabled={disabled}
          />

          <button type="submit" disabled={disabled}>
            <IoSendSharp size={26} color={!disabled ? "#733eb1" : "#6b7280"} />
          </button>
        </form>
        {showHeaderAndFooter && (
          <div className={S.CopyRight}>
            <p>
              Powered by <span>EnglishBay</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

Chat.propTypes = {
  isGif: PropTypes.bool,
  showHeaderAndFooter: PropTypes.bool,
  initialMessages: PropTypes.array,
  messages: PropTypes.array,
  showChat: PropTypes.bool,
  setShowChat: PropTypes.func,
  setShowModal: PropTypes.func,
};

export default Chat;
