import React, { useEffect, useContext, useState } from "react";
import Cards from "react-credit-cards";
import { BiChevronLeft } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import Turnstile from "react-turnstile";
import { getInstallments } from "utils/utils";

import Button from "../../../_Shared/Button/Button";
import { InputTwo, RadioButton } from "../../../_Shared/Form";
import Input from "../../../_Shared/Input/Input";
import Select from "../../../_Shared/Select/Select";
import Switcher from "../../../_Shared/Switcher/Switcher";
import Warning from "../../../_Shared/Warning/Warning";
import { SpinnerWhite } from "../../../../assets/svgs";
import CardBankBillPaymentSuccess from "../../../../components/_Shared/Slide/PaymentsCards/CardBankBillPaymentSuccess/CardBankBillPaymentSuccess";
import CreditCardPaymentSuccess from "../../../../components/_Shared/Slide/PaymentsCards/CreditCardPaymentSuccess/CreditCardPaymentSuccess";
import PaymentUnsuccessful from "../../../../components/Auth/PaymentUnsuccessful/PaymentUnsuccessful";
import { NavbarContext } from "../../../../context/NavbarContext";
import { SlideContext } from "../../../../context/SlideContext";
import { ToastContext } from "../../../../context/ToastContext";
import { UserContext } from "../../../../context/UserContext";
import useForm from "../../../../hooks/useForm";
import useRequest from "../../../../hooks/useRequest";
import { pushGTMEvent } from "../../../../utils/GTM";
import { getCurrentPlan } from "../../../../utils/localStorage";
import statesData from "../../../../utils/statesAndCities";

import "react-credit-cards/es/styles-compiled.css";
import "./Checkout.css";

function Checkout() {
  const { setNavigationLinks } = useContext(NavbarContext);
  const { request } = useRequest();
  const { updateUserContext, getCredits, user, setCaptchaToken } = useContext(UserContext);

  const { showToast } = useContext(ToastContext);

  const [paymentMethod, setPaymentMethod] = useState("credit-card");
  const [cData, setCData] = useState(Date.now().toString());
  const { setSlideComponent, setShowSlide } = useContext(SlideContext);

  const [isLoading, setIsLoading] = useState(false);
  const [installmentsOptions, setInstallmentsOptions] = useState();
  const [installments, setInstallments] = useState(1);
  const [address, setAdress] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [focus, setFocus] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [plan, setPlan] = useState({});
  const [couponFormValue, setCouponFormValue] = useState("");
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [coupon, setCoupon] = useState(null);
  const [price, setPrice] = useState(0);
  const [terms, setTerms] = useState("");
  const [privacy, setPrivacy] = useState("");

  const acceptedTermsForm = useForm();
  const cpfForm = useForm("cpf");
  const cardNumberForm = useForm("cardNumber");
  const cardholderNameForm = useForm();
  const cardExpirationForm = useForm();
  const cardCVVForm = useForm();

  const cepForm = useForm("cep");

  const history = useHistory();

  const checkStoredCoupon = async () => {
    const coupon = localStorage.getItem("couponCode") ?? sessionStorage.getItem("coupon");

    if (!coupon) {
      return;
    }

    const planUuid = getCurrentPlan().uuid;
    const response = await request("POST", "/coupon/check", { planUuid, code: coupon }, true, false);

    if (response.error) {
      return;
    }

    setCouponFormValue(coupon);
    setCoupon(response);
  };

  useEffect(() => {
    setNavigationLinks([
      { title: "Início", path: "/" },
      { title: "Planos", path: "/planos" },
      { title: "Pagamento", path: "/planos/checkout" },
    ]);

    setStates(mountStatesList(statesData));
    const plan = getCurrentPlan();

    setPlan(plan);
    fetchTerms();
    checkStoredCoupon();
  }, []);

  useEffect(() => {
    if (state) {
      const foundState = statesData.find(({ sigla }) => sigla == state);
      setCities(foundState.cidades);
    }
  }, [state]);

  useEffect(() => {
    const zipCodeFormated = cepForm.value.replace(/\D/gi, "");

    if (zipCodeFormated.length == 8) {
      fetchZipCodeData(zipCodeFormated).then((response) => {
        if (response.erro) {
          setAdress("");
          setNeighborhood("");
          setCity("");
          return;
        }

        setAdress(response.logradouro);
        setNeighborhood(response.bairro);
        setCity(response.localidade);
        setState(response.uf);
      });
    }
  }, [cepForm.value]);

  useEffect(() => {
    if (!plan) {
      return;
    }

    if (plan.price) {
      if (discount > 0) {
        setPrice(toFixed(plan.price - discount, 2));
        return;
      }

      setPrice(plan.price);
    }
  }, [plan]);

  useEffect(() => {
    if (coupon?.discountPercentage) {
      setDiscount(toFixed(plan.price * (coupon.discountPercentage / 100), 2));
    }
  }, [coupon]);

  useEffect(() => {
    if (discount > 0) {
      setPrice(toFixed(plan.price - discount, 2));
    }
  }, [discount]);

  useEffect(() => {
    if (price) {
      setInstallmentsOptions(getInstallments(price, 12));
    }
  }, [price]);

  const fetchTerms = async () => {
    const userType = user?.role === "TEACHER" ? "TEACHER" : "USER";

    const [term, privacy] = await Promise.all([
      request("GET", `/terms/read?kind=TERM&userType=${userType}`),
      request("GET", `/terms/read?kind=PRIVACY&userType=${userType}`),
    ]);

    setTerms(term.text);
    setPrivacy(privacy.text);
  };

  const fetchZipCodeData = async (zipCode) => {
    const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json/`);
    const data = await response.json();
    return data;
  };

  const mountStatesList = (data) => {
    return data.map((state) => state.sigla);
  };

  const handleCoupon = async (event, coupon) => {
    event?.preventDefault();
    const planUuid = getCurrentPlan().uuid;

    setIsCouponLoading(true);

    const cuponReq = {
      code: coupon || couponFormValue,
      planUuid,
    };

    const response = await request("POST", "/coupon/check", cuponReq, true, false);

    if (response.error) {
      handleInvalidCoupon();
      return;
    }

    setCoupon(response);
    setIsCouponLoading(false);
  };

  const toFixed = (value, precision) => {
    const regex = /\./gi;

    if (!regex.test(value)) {
      return value.toFixed(2);
    }

    var decimalPlaces = Math.pow(10, precision);
    return Math.floor(value * decimalPlaces) / decimalPlaces;
  };

  const saveGTMEvent = (purchase) => {
    pushGTMEvent({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: purchase?.billId, // Transaction ID. Required for purchases and refunds. (Vindi Bill Id)
            affiliation: "EnglishBay",
            revenue: purchase?.bill?.amount, // Total transaction value (incl. tax and shipping)
            tax: null,
            shipping: null,
            coupon: purchase?.coupon?.code ? purchase?.coupon.code : null, //Retorna o cupom caso for utilizado na compra
          },
          products: [
            {
              // List of productFieldObjects.
              name: purchase?.plan?.name, // Name or ID is required. Nome da assinatura
              id: purchase?.plan?.uuid,
              price: purchase?.plan?.price,
              brand: null,
              category: purchase?.plan?.lessonType,
              variant: "null",
              quantity: 1,
              coupon: purchase?.coupon?.code ? purchase?.coupon.code : null,
            },
          ],
        },
      },
    });
  };

  const handleInvalidCoupon = () => {
    showToast({ type: "error", message: "Cupom inválido" });
    setPrice(plan.price);
    setDiscount(0);
    setCouponFormValue("");
    setIsCouponLoading(false);
  };

  const handleCheckout = () => {
    paymentMethod === "credit-card" ? handleCreditCardCheckout() : handleBankBillCheckout();
  };

  const handleBankBillCheckout = async () => {
    const formIsValid =
      acceptedTermsForm.validate() && cpfForm.validate() && cardholderNameForm.validate() && cepForm.validate();

    const paymentRequest = {
      cpf: cpfForm.value,
      planUuid: getCurrentPlan().uuid,
      paymentMethodCode: "pix_bank_slip",
      cep: cepForm.value,
      street: address,
      neighborhood: neighborhood,
      complement: complement,
      state: state,
      city: city,
      number: number,
      installments,
    };

    const planUuid = getCurrentPlan().uuid;

    if (coupon) {
      paymentRequest.couponCode = coupon.code;
    }

    if (formIsValid) {
      setIsLoading(true);
      const isReactivatePlan = user.role === "STUDENT";

      const response = await request(
        "POST",
        `/subscription/${isReactivatePlan ? "subscribe" : "new"}`,
        paymentRequest,
        true,
        false,
        true,
      );

      setCData(Date.now().toString()); // reseta captcha

      if (!response.error) {
        await updateUserContext();
        await getCredits();
        saveGTMEvent(response);
        setIsLoading(false);

        setSlideComponent(
          <CardBankBillPaymentSuccess
            discount={coupon}
            uuid={planUuid}
            barCode={
              response.bill.charges[response.bill.charges.length - 1].last_transaction.gateway_response_fields[
                process.env.REACT_APP_NODE_ENV === "prod" ? "typeable_barcode" : "typable_barcode"
              ]
            }
            linkTicket={response.bill.charges[response.bill.charges.length - 1].print_url}
            pixQrCodeUrl={
              response.bill.charges[response.bill.charges.length - 1].last_transaction.gateway_response_fields
                .qrcode_path
            }
            pixCode={
              response.bill.charges[response.bill.charges.length - 1].last_transaction.gateway_response_fields
                .qrcode_original_path
            }
          />,
        );
        setShowSlide(true);

        window.open(response.bill.charges[response.bill.charges.length - 1].print_url);
        return;
      }

      setIsLoading(false);

      if (response.error?.statusCode !== 201) {
        if (response.error?.message === "INVALID_CPF") {
          return showToast({ message: "CPF Inválido!", type: "error" });
        }

        if (response.error?.message === "ERR_INVALID_PHONE_NUMBER") {
          return showToast({ message: "Número de telefone inválido! Altere seu número em seu perfil", type: "error" });
        }

        if (response.error?.message === "ERR_INVALID_STATE") {
          return showToast({ message: "Estado inválido!", type: "error" });
        }

        if (response.error?.message === "ERR_INVALID_CEP") {
          return showToast({ message: "CEP inválido!", type: "error" });
        }

        if (response.error?.statusCode === 500) {
          return showToast({ message: "Erro interno ao processar compra!", type: "error" });
        }

        return showToast({ message: "Preencha todos os dados corretamente!", type: "error" });
      }
    }
  };

  const handleCreditCardCheckout = async () => {
    const isValidForm =
      cpfForm.validate() &&
      cardholderNameForm.validate() &&
      cardExpirationForm.validate() &&
      cardNumberForm.validate() &&
      cardCVVForm.validate() &&
      acceptedTermsForm.validate();

    const planUuid = getCurrentPlan().uuid;

    const paymentRequest = {
      cpf: cpfForm.value,
      planUuid,
      paymentMethodCode: "credit_card",
      card: {
        holderName: cardholderNameForm.value,
        cardExpiration: cardExpirationForm.value,
        cardNumber: cardNumberForm.value.split(" ").join(""),
        cardCVV: cardCVVForm.value,
      },
      installments,
    };

    if (coupon) {
      paymentRequest.couponCode = coupon.code;
    }

    if (isValidForm) {
      setIsLoading(true);
      const isReactivatePlan = user.role === "STUDENT";

      const response = await request(
        "POST",
        `/subscription/${isReactivatePlan ? "subscribe" : "new"}`,
        paymentRequest,
        true,
        false,
        true,
      );

      setCData(Date.now().toString());

      if (!response.error) {
        await updateUserContext();
        await getCredits();
        saveGTMEvent(response);
        setIsLoading(false);

        setSlideComponent(<CreditCardPaymentSuccess response={response} discount={coupon} />);
        setShowSlide(true);
        return;
      }

      setIsLoading(false);
      if (response.error?.statusCode !== 201) {
        if (response.error?.message === "INVALID_CPF") {
          return showToast({ message: "CPF Inválido!", type: "error" });
        }

        if (response.error?.message === "CARD_REJECTED") {
          setSlideComponent(<PaymentUnsuccessful />);
          setShowSlide(true);
          return;
        }

        if (response.error?.statusCode === 500) {
          return showToast({ message: "Erro interno ao processar compra!", type: "error" });
        }

        return showToast({ message: "Preencha todos os dados corretamente!", type: "error" });
      }
    }
  };

  return (
    <section className="checkout__section shadow-sm">
      <div
        className="flex items-center cursor-pointer border border-black rounded-full mb-4 px-2"
        style={{ maxWidth: "80px" }}
        onClick={() => history.goBack()}>
        <BiChevronLeft size={16} />
        <span>Voltar</span>
      </div>

      <span className="section__title">Contratar plano</span>

      <p className="block mt-2">Qual será a forma de pagamento?</p>

      <div className="switcher__container">
        <div>
          <Switcher
            option1="Cartão de crédito"
            option2="Pix/Boleto"
            onClick1={() => setPaymentMethod("credit-card")}
            onClick2={() => setPaymentMethod("pix_bank_slip")}
          />
        </div>
      </div>

      <div className="warning--desktop">
        {plan?.lessonType === "CONVERSATION" && (
          <Warning>Lembre-se: Esse plano é recorrente e pode ser cancelado a qualquer momento sem multa</Warning>
        )}

        {plan?.lessonType !== "CONVERSATION" && (
          <Warning>
            Lembre-se: <strong>A cobrança é mensal!</strong> Suas aulas tem validade de 45 dias.
          </Warning>
        )}
      </div>

      {paymentMethod === "credit-card" && (
        <div className="credit-card__container">
          <Cards
            cvc={cardCVVForm.value}
            expiry={cardExpirationForm.value}
            name={cardholderNameForm.value}
            number={cardNumberForm.value}
            focused={focus}
          />

          <form className="flex flex-col gap-5">
            <InputTwo
              mask="9999 9999 9999 9999"
              label="Número do cartão"
              placeholder="Insira o numero"
              name="number"
              onFocus={() => setFocus("number")}
              {...cardNumberForm}
            />

            <div className="flex gap-4 justify-between">
              <div style={{ width: "50%" }}>
                <InputTwo
                  mask="99/99"
                  placeholder="Insira a validade"
                  label="Validade"
                  name="expiry"
                  onFocus={() => setFocus("number")}
                  {...cardExpirationForm}
                />
              </div>

              <div style={{ width: "50%" }}>
                <InputTwo
                  mask="999"
                  placeholder="Insira o código"
                  label="cvv"
                  name="cvc"
                  onFocus={() => setFocus("cvc")}
                  {...cardCVVForm}
                />
              </div>
            </div>

            <InputTwo
              placeholder="Digite o nome do responsável"
              label="Nome impresso no cartão"
              type="text"
              name="name"
              onFocus={() => setFocus("name")}
              {...cardholderNameForm}
            />

            <InputTwo
              mask="999.999.999-99"
              placeholder="Digite o CPF do responsável"
              label="Digite seu CPF"
              name="cpf"
              {...cpfForm}
            />

            {plan?.intervalCount === 12 && (
              <Select
                label="Parcelas"
                options={installmentsOptions}
                onChange={({ target }) => setInstallments(target.value.split("x")[0])}
              />
            )}
          </form>
        </div>
      )}

      {["bank_slip", "pix_bank_slip"].includes(paymentMethod) && (
        <form className="mt-10 flex flex-col gap-5">
          <div className="flex gap-4 form-row">
            <InputTwo
              placeholder="Digite o nome do responsável"
              label="Digite o nome do responsável"
              type="text"
              name="name"
              {...cardholderNameForm}
            />

            <InputTwo
              mask="999.999.999-99"
              placeholder="Digite o CPF do responsável"
              label="Digite seu CPF"
              name="cpf"
              {...cpfForm}
            />
          </div>

          <div className="flex gap-4 form-row">
            <InputTwo placeholder="Informe o seu CEP" label="Digite seu CEP" mask="99999999" name="cep" {...cepForm} />

            <Input
              placeholder="Informe o seu endereço"
              label="Endereço"
              value={address}
              onChange={({ target }) => setAdress(target.value)}
            />
          </div>

          <div className="flex gap-4 form-row">
            <Input
              placeholder="Informe o seu complemento"
              label="Complemento"
              value={complement}
              onChange={({ target }) => setComplement(target.value)}
            />

            <Input
              placeholder="Informe o número do endereço"
              label="Número"
              value={number}
              onChange={({ target }) => setNumber(target.value)}
            />
          </div>

          <div className="flex gap-4 form-row">
            <Input
              placeholder="Informe o seu bairro"
              label="Bairro"
              value={neighborhood}
              onChange={({ target }) => setNeighborhood(target.value)}
            />

            <Select label="Estado" options={states} onChange={({ target }) => setState(target.value)} value={state} />
            <Select label="Cidade" options={cities} onChange={({ target }) => setCity(target.value)} value={city} />
          </div>
        </form>
      )}

      <div className="mt-8 mb-6 border-t pt-6 discount__container">
        <>
          <span className="font-bold mb-2 block">Cupom de desconto</span>

          <form className="flex gap-4 coupon-form" onSubmit={handleCoupon}>
            <Input
              width="70%"
              bg="#F9F9F9"
              border="1px solid #E3E6E8"
              value={couponFormValue}
              required
              onChange={({ target }) => setCouponFormValue(target.value)}
            />

            <Button bg="#733EB1" color="#FFF" width="30%">
              {isCouponLoading ? <SpinnerWhite className="w-7 mx-auto animate-spin" /> : "Aplicar"}
            </Button>
          </form>
        </>
      </div>

      <div className="price__container">
        {discount > 0 && (
          <>
            <div>
              Plano
              <span>R$ {plan.price}</span>
            </div>

            <div>
              Desconto
              <span>R$ {discount}</span>
            </div>
          </>
        )}

        <div className="price--total">
          Total
          <span>R$ {price}</span>
        </div>
      </div>

      <div className="warning--mobile">
        {plan?.lessonType === "CONVERSATION" && (
          <Warning>Lembre-se: Esse plano é recorrente e pode ser cancelado a qualquer momento sem multa</Warning>
        )}

        {plan?.lessonType !== "CONVERSATION" && (
          <Warning>
            Lembre-se: <strong>A cobrança é mensal!</strong> Suas aulas tem validade de 45 dias.
          </Warning>
        )}
      </div>

      <div className="d-flex" style={{ display: "flex", justifyContent: "left" }}>
        <Turnstile
          className="mb-3"
          sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
          theme="light"
          onVerify={setCaptchaToken}
          cData={cData}
        />
      </div>

      <div className="flex flex-col justify-center items-center gap-5">
        <div style={{ fontSize: "0.8em" }}>
          <RadioButton terms={terms} privacy={privacy} {...acceptedTermsForm} />
        </div>

        <Button bg="#733EB1" color="#FFF" className="btn-checkout" onClick={handleCheckout} disabled={isLoading}>
          {isLoading ? <SpinnerWhite className="w-7 mx-auto animate-spin" /> : "Finalizar compra"}
        </Button>
      </div>
    </section>
  );
}

export default Checkout;
